import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { toast } from 'react-toastify'
// labotarotyAction for all laboratory cases
export const getLabTestorderList = createAsyncThunk(
    'laboratory/test-order/get',
    async (arg, { getState, rejectWithValue }) => {
        try {
            // get user data from store
            const { user } = getState()
            // console.log(`Bearer ${localStorage.getItem("userToken")}`);
            // configure authorization header with user's token
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("userToken")}`,
                },
            }
            // console.log(localStorage.getItem("userToken"))
            const { data } = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/laboratory/test-orders`,
                config
            )
            console.log(data);
            return data
        } catch (error) {
            console.log(Error);
            toast.error(error.response?.data?.error_description, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)
export const updateLabTestorder = createAsyncThunk(
    'laboratory/test-order/update',
    async (arg, { getState, rejectWithValue }) => {

        const { order_id, status, total_price } = arg;
        try {
            // get user data from store
            const { user } = getState()
            // console.log(`Bearer ${localStorage.getItem("userToken")}`);
            // configure authorization header with user's token
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("userToken")}`,
                },
            }
            // console.log(localStorage.getItem("userToken"))
            const params = new URLSearchParams({
                order_id: order_id,
                status: status,
                total_price: total_price
            }).toString();
            const url =
                `${process.env.REACT_APP_BASE_URL}/laboratories/test-order/reply?order_id=${order_id}&status=Active&total_price=${total_price}`
            const data = await axios.put(
                url,
                null,
                config
            )
                .then((res) => {
                    if (res) {
                        toast.success('Test Order Update success!', {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });
                    }
                });

            console.log(data);
            return data
        } catch (error) {
            console.log(Error);
            toast.error(error.response?.data?.error_description, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)
