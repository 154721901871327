import { createSlice } from '@reduxjs/toolkit'
import { addAdmin, approvePatinetConsultation, approvePatinetLabTestOrder, deleteAdmin, getAdminsList, getLastThreeMonth } from './adminsActions';


const initialState = {
    loading: false,
    admins: [],
    error: null,
    success: false,

    ///ADD ADMINS
    addAdminLoading: false,
    addAdminError: null,
    addAdminSuccess: false,

    ///DELETE ADMINS
    deleteAdminLoading: false,
    deleteAdminError: null,
    deleteAdminSuccess: false,




}


const adminsSlice = createSlice({
    name: 'admins',
    initialState,
    reducers: {
    },
    extraReducers: {

        // get Admins list
        [getAdminsList.pending]: (state) => {
            console.log('Admins list fetch loading...');
            state.loading = true
        },
        [getAdminsList.fulfilled]: (state, { payload }) => {
            console.log('Admins list fetched...');
            state.loading = false
            state.admins = payload
        },
        [getAdminsList.rejected]: (state, { payload }) => {
            console.log('Admins list rejected...');
            state.loading = false
        },


        // add Admins 
        [addAdmin.pending]: (state) => {
            state.addAdminLoading = true
        },
        [addAdmin.fulfilled]: (state, { payload }) => {
            state.addAdminLoading = false
            state.addAdminSuccess = true
        },
        [addAdmin.rejected]: (state, { payload }) => {
            state.addAdminLoading = false
            state.addAdminError = payload
        },



        // delete Admins 
        [deleteAdmin.pending]: (state) => {
            state.deleteAdminLoading = true
        },
        [deleteAdmin.fulfilled]: (state, { payload }) => {
            state.deleteAdminLoading = false
            state.deleteAdminSuccess = true
        },
        [deleteAdmin.rejected]: (state, { payload }) => {
            state.deleteAdminLoading = false
            state.deleteAdminError = payload
        },

        // getLastThreeMonth 
        [getLastThreeMonth.pending]: (state) => {
            state.deleteAdminLoading = true
        },
        [getLastThreeMonth.fulfilled]: (state, { payload }) => {
            state.deleteAdminLoading = false
            state.deleteAdminSuccess = true
        },
        [getLastThreeMonth.rejected]: (state, { payload }) => {
            state.deleteAdminLoading = false
            state.deleteAdminError = payload
        },
        
    },
})

export default adminsSlice.reducer