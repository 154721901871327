import { createSlice } from '@reduxjs/toolkit'
import { getLabTestorderList, updateLabTestorder } from './laboratoriestAction';


const initialState = {

    //get test orders
    loading: false,
    labTestOrder: [],
    error: null,
    success: false,

    //update test orders
    updateTestOrderLoading: false,
    updateTestOrderError: null,
    updateTestOrderSuccess: false,


}
const labTestOrderSlice = createSlice({
    name: 'labTestOrder',
    initialState,
    reducers: {
    },
    extraReducers: {

        // get Test orders list
        [getLabTestorderList.pending]: (state) => {
            state.loading = true
        },
        [getLabTestorderList.fulfilled]: (state, { payload }) => {
            console.log(payload);
            state.loading = false
            state.labTestOrder = payload
        },
        [getLabTestorderList.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },

        // update Test order
        [updateLabTestorder.pending]: (state) => {
            state.updateTestOrderLoading = true
        },
        [updateLabTestorder.fulfilled]: (state, { payload }) => {
            console.log(payload);
            state.updateTestOrderLoading = false
        },
        [updateLabTestorder.rejected]: (state, { payload }) => {
            state.updateTestOrderLoading = false
            state.updateTestOrderError = payload
        },
    },
})

export default labTestOrderSlice.reducer