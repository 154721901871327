import { createSlice } from '@reduxjs/toolkit'
import { acceptRejectLabRegistration, acceptRejectRegistration, addAdmin, deleteAdmin, getAdminsList } from './CommonActions';


const initialState = {
    acceptRejectLoading: false,
    data: [],
    acceptRejectError: false,
    acceptRejectSuccess: false,

    ///FOR LAB
    labAcceptLoading: false,
    labAcceptError: false,
    labAcceptSuccess: false,
}


const CommonSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {
    },
    extraReducers: {

        // acceptRejectRegistration
        [acceptRejectRegistration.pending]: (state) => {
            state.acceptRejectLoading = true
        },
        [acceptRejectRegistration.fulfilled]: (state, { payload }) => {
            state.acceptRejectLoading = false
            state.acceptRejectSuccess = true
        },
        [acceptRejectRegistration.rejected]: (state, { payload }) => {
            state.acceptRejectLoading = false
            state.acceptRejectError = true
        },


        // FOR LAB
        [acceptRejectLabRegistration.pending]: (state) => {
            state.labAcceptLoading = true
        },
        [acceptRejectLabRegistration.fulfilled]: (state, { payload }) => {
            state.labAcceptLoading = false
            state.labAcceptSuccess = true
        },
        [acceptRejectLabRegistration.rejected]: (state, { payload }) => {
            state.labAcceptLoading = false
            state.labAcceptError = true
        },
    },
})

export default CommonSlice.reducer