import { createSlice } from '@reduxjs/toolkit'
import { getDeliverersList, addDeliverers, deleteDeliverers, updateDeliverers, getPendingDeliverersList } from './DeliverersAction';


const initialState = {
    loading: false,
    deliverers: [],
    error: null,
    success: false,

    ///ADD deliverers
    addDeliverersLoading: false,
    addDeliverersError: null,
    addDeliverersSuccess: false,

    ///DELETE deliverers
    deleteDeliverersLoading: false,
    deleteDeliverersError: null,
    deleteDeliverersSuccess: false,


    ///UPDATE deliverers
    updateDeliverersLoading: false,
    updateDeliverersError: null,
    updateDeliverersSuccess: false,

    ///PENDING deliverers
    getPendingDeliverersListError: null,
    getPendingDeliverersListSuccess: false,
    pendingDeliverers: [],
}


const adminsSlice = createSlice({
    name: 'deliverers',
    initialState,
    reducers: {
        
    },
    extraReducers: {

        // get Admins list
        [getDeliverersList.pending]: (state) => {
            state.loading = true
        },
        [getDeliverersList.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.deliverers = payload
        },
        [getDeliverersList.rejected]: (state, { payload }) => {
            state.loading = false
        },


        // add Deliverers 
        [addDeliverers.pending]: (state) => {
            state.addDeliverersLoading = true
        },
        [addDeliverers.fulfilled]: (state, { payload }) => {
            state.addDeliverersLoading = false
            state.addDeliverersSuccess = true
        },
        [addDeliverers.rejected]: (state, { payload }) => {
            state.addDeliverersLoading = false
            state.addDeliverersError = payload
        },



        // delete deliverers 
        [deleteDeliverers.pending]: (state) => {
            state.deleteDeliverersLoading = true
        },
        [deleteDeliverers.fulfilled]: (state, { payload }) => {
            state.deleteDeliverersLoading = false
            state.deleteDeliverersSuccess = true
        },
        [deleteDeliverers.rejected]: (state, { payload }) => {
            state.deleteDeliverersLoading = false
            state.deleteDeliverersError = payload
        },

        // update deliverers 
        [updateDeliverers.pending]: (state) => {
            state.updateDeliverersLoading = true
        },
        [updateDeliverers.fulfilled]: (state, { payload }) => {
            state.updateDeliverersLoading = false
            state.updateDeliverersSuccess = true
        },
        [updateDeliverers.rejected]: (state, { payload }) => {
            state.updateDeliverersLoading = false
            state.updateDeliverersError = payload
        },

        // Pending deliverers 
        [getPendingDeliverersList.pending]: (state) => {
            state.loading = true
        },
        [getPendingDeliverersList.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.getPendingDeliverersListSuccess = true
            state.pendingDeliverers=payload
        },
        [getPendingDeliverersList.rejected]: (state, { payload }) => {
            state.loading = false
            state.getPendingDeliverersListError = payload
        },
    },
})

export default adminsSlice.reducer