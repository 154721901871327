// /* eslint-disable no-const-assign */
// import { createSlice } from '@reduxjs/toolkit'

import { createSlice } from "@reduxjs/toolkit"



const initialState = {
    sidebarShow: true,
  }


const drawerSlice = createSlice({
  name: 'showSideBar',
  initialState,

  reducers: {
    changeSideBar: (state,{ payload }) => {
        console.log(payload);
        state.sidebarShow=payload;
        // state.sidebarShow=visible
    },
  },
 
})
export const { changeSideBar } = drawerSlice.actions
export default drawerSlice.reducer