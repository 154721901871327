import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { toast } from 'react-toastify'

export const drugCategoryList = createAsyncThunk(
    'drug/category/get',
    async (arg, { getState, rejectWithValue }) => {

        try {
            // configure authorization header with user's token
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("userToken")}`,
                },
            }
            const url =
                "${process.env.REACT_APP_BASE_URL}/drug-categories"

            const { data } = await axios.get(url,
                config
            )
            console.log(data);
            return data
        } catch (error) {
            console.log(error);
            console.log(error.response);
            toast.error(error?.message, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            console.log(error.response.data.message);
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)
export const testCategoryList = createAsyncThunk(
    'test/category/gett',
    async (arg, { getState, rejectWithValue }) => {
        try {
            // configure authorization header with user's token
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("userToken")}`,
                    'Content-Type': 'application/json',
                    // "Access-Control-Allow-Origin": "*"
                },
            }
            const url =
                "${process.env.REACT_APP_BASE_URL}/test-categories"

            const { data } = await axios.get(url,
                config
            )
            console.log(data);
            return data
        } catch (error) {
            console.log(error);
            console.log(error.response);
            toast.error(error?.message, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            console.log(error.response.data.message);
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)
///add drug category
export const addDrugCategory = createAsyncThunk(
    'drug/category/add',
    async (arg, { getState, rejectWithValue, dispatch }) => {
        const { id, status, role } = arg;
        console.log(id, status, role);
        try {
            // configure authorization header with user's token
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("userToken")}`,
                    'Content-Type': 'application/json',
                    // "Access-Control-Allow-Origin": "*"
                },
            }
            const url =
                "${process.env.REACT_APP_BASE_URL}/drug-categories"

            const data = await axios.post(url, arg,
                config
            )
                .then((res) => {
                    if (res) {
                        toast.success('Drug category add success!', {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });
                        dispatch(drugCategoryList())
                    }
                }).catch(function (error) {
                    if (error.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        console.log(error.response?.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                        toast.error(error.response?.data, {
                            position: toast.POSITION.BOTTOM_RIGHT
                        })
                    } else if (error.request) {
                        // The request was made but no response was received
                        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                        // http.ClientRequest in node.js
                        console.log(error.request);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message);
                    }
                    console.log(error.config);
                });
            console.log(data);
            return data
        } catch (error) {
            console.log(error);
            console.log(error.response);
            toast.error(error?.message, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            console.log(error.response.data.message);
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)

///add test category
export const addTestCategory = createAsyncThunk(
    'test/category/add',
    async (arg, { getState, rejectWithValue, dispatch }) => {
        try {
            // configure authorization header with user's token
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("userToken")}`,
                    'Content-Type': 'application/json',
                    // "Access-Control-Allow-Origin": "*"
                },
            }
            const url =
                "${process.env.REACT_APP_BASE_URL}/test-categories"

            const data = await axios.post(url, arg,
                config
            )
                .then((res) => {
                    if (res) {
                        toast.success('Lab Test category add success!', {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });
                        dispatch(testCategoryList())
                    }
                }).catch(function (error) {
                    if (error.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        console.log(error.response?.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                        toast.error(error.response?.data, {
                            position: toast.POSITION.BOTTOM_RIGHT
                        })
                    } else if (error.request) {
                        // The request was made but no response was received
                        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                        // http.ClientRequest in node.js
                        console.log(error.request);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message);
                    }
                    console.log(error.config);
                });
            console.log(data);
            return data
        } catch (error) {
            console.log(error);
            console.log(error.response);
            toast.error(error?.message, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            console.log(error.response.data.message);
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)


///delete drug category
export const deleteDrugCategory = createAsyncThunk(
    'drug/category/delete',
    async (arg, { getState, rejectWithValue, dispatch }) => {
        try {
            // configure authorization header with user's token
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("userToken")}`,
                    'Content-Type': 'application/json',
                    // "Access-Control-Allow-Origin": "*"
                },
            }
            const url =
                `${process.env.REACT_APP_BASE_URL}/drug-categories/${arg}`

            const data = await axios.delete(url,
                config
            )
                .then((res) => {
                    if (res) {
                        toast.success('Drug category delete success!,', {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });
                        dispatch(drugCategoryList())
                    }
                }).catch(function (error) {
                    if (error.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        console.log(error.response?.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                        toast.error(error.response?.data, {
                            position: toast.POSITION.BOTTOM_RIGHT
                        })
                    } else if (error.request) {
                        // The request was made but no response was received
                        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                        // http.ClientRequest in node.js
                        console.log(error.request);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message);
                    }
                    console.log(error.config);
                });
            console.log(data);
            return data
        } catch (error) {
            console.log(error);
            console.log(error.response);
            toast.error(error?.message, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            console.log(error.response.data.message);
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)
///delete test category
export const deleteTestCategory = createAsyncThunk(
    'test/category/delete',
    async (arg, { getState, rejectWithValue, dispatch }) => {
        try {
            // configure authorization header with user's token
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("userToken")}`,
                    'Content-Type': 'application/json',
                    // "Access-Control-Allow-Origin": "*"
                },
            }
            const url =
                `${process.env.REACT_APP_BASE_URL}/test-categories/${arg}`

            const data = await axios.delete(url,
                config
            )
                .then((res) => {
                    if (res) {
                        toast.success('Lab Test category deleted success!', {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });

                        dispatch(testCategoryList())
                    }
                }).catch(function (error) {
                    if (error.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        console.log(error.response?.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                        toast.error(error.response?.data, {
                            position: toast.POSITION.BOTTOM_RIGHT
                        })
                    } else if (error.request) {
                        // The request was made but no response was received
                        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                        // http.ClientRequest in node.js
                        console.log(error.request);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message);
                    }
                    console.log(error.config);
                });
            console.log(data);
            return data
        } catch (error) {
            console.log(error);
            console.log(error.response);
            toast.error(error?.message, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            console.log(error.response.data.message);
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)


////---------------------------------------------------------------------------------------------
//HOME CARE AND EMERGENCY PART
////---------------------------------------------------------------------------------------------

//add homecare/emergency category
export const addHomecareEmergencyCat = createAsyncThunk(
    'addEmergencyCat/create',
    async (arg, { getState, rejectWithValue }) => {
        console.log(arg);
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("userToken")}`,
                    'Content-Type': 'multipart/form-data'
                },
            }
            const data = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/admins/emergency-and-home-care-service-categories`, arg,
                config
            )
                .then((res) => toast.success('Operation success', {
                    position: toast.POSITION.BOTTOM_RIGHT
                }))
            console.log(data);
            return data
        } catch (error) {
            console.log(error);
            console.log(error.response);
            toast.error(error?.response?.data?.error_description, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            console.log(error.response.data.message);
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }

        }
    }
)
export const getHomecareEmergencyCatList = createAsyncThunk(
    'EmergencyCat/get/all',
    async (arg, { getState, rejectWithValue }) => {
        console.log(arg);
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("userToken")}`,
                },
            }
            const data = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/admins/emergency-and-home-care-service-categories`,
                config
            )
            console.log(data);
            return data
        } catch (error) {
            console.log(error);
            console.log(error.response);
            toast.error(error?.message, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            console.log(error.response.data.message);
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }

        }
    }
)

////---------------------------------------------------------------------------------------------
//DOCTOR PART
////---------------------------------------------------------------------------------------------

//add DOCOTR CATEGORY SERVICE
export const addDoctorCat = createAsyncThunk(
    'doctorCat/create',
    async (arg, { getState, rejectWithValue }) => {
        console.log(arg.get('img'));
        console.log(arg.get('name'));
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("userToken")}`,
                    'Content-Type': 'multipart/form-data'
                },
            }
            const data = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/specialities`, arg,
                config
            )
                .then((res) => toast.success('Operation success', {
                    position: toast.POSITION.BOTTOM_RIGHT
                }))
            console.log(data);
            return data
        } catch (error) {
            console.log(error);
            console.log(error.response);
            toast.error(error?.response?.data?.error_description, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            console.log(error.response.data.message);
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }

        }
    }
)
export const getDoctorCatList = createAsyncThunk(
    'DoctorCat/get/alll',
    async (arg, { getState, rejectWithValue }) => {
        console.log(arg);

        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("userToken")}`,
                    'Content-Type': 'application/json'
                },
            }
            const data = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/doctors/specialities`,
                config
            )
            // .then((res) => console.log(res.data))
            console.log(data);
            return data
        } catch (error) {
            console.log(error);
            console.log(error.response);
            toast.error(error?.message, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            console.log(error.response.data.message);
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }

        }
    }
)
