import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
// commonAction.js
export const acceptRejectRegistration = createAsyncThunk(
  "common/acceptRejectt/deliverer",
  async (arg, { getState, rejectWithValue }) => {
    const { id, status, role } = arg;
    console.log(id, status, role);
    try {
      // configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          "Content-Type": "application/json",
          // "Access-Control-Allow-Origin": "*"
        },
      };

      const params = new URLSearchParams({
        id: id,
        status: status,
        role: role,
      }).toString();
      const url = `${process.env.REACT_APP_BASE_URL}/admins/approve?` + params;

      const data = await axios
        .put(url, null, config)
        .then((res) => {
          if (res) {
            toast.success("The user is Accepted!", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        })
        .catch(function (error) {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response?.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            toast.error(error.response?.data, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
          console.log(error.config);
        });

      console.log(data);
      return data;
    } catch (error) {
      console.log(error);
      console.log(error.response);
      toast.error(error?.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      console.log(error.response.data.message);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const acceptRejectLabRegistration = createAsyncThunk(
  "common/acceptRejectt/Lab",
  async (arg, { getState, rejectWithValue }) => {
    const { id, status, role } = arg;
    console.log(id, status, role);
    try {
      // configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          "Content-Type": "application/json",
          // "Access-Control-Allow-Origin": "*"
        },
      };
      const params = new URLSearchParams({
        id: id,
        status: "ACTIVE",
        role: "LABORATORY",
      }).toString();
      const url = "${process.env.REACT_APP_BASE_URL}/admins/approve?" + params;

      const data = await axios
        .put(url, null, config)
        .then((res) => {
          if (res) {
            toast.success("The user is Accepted!", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        })
        .catch(function (error) {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response?.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            toast.error(error.response?.data, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
          console.log(error.config);
        });

      console.log(data);
      return data;
    } catch (error) {
      console.log(error);
      console.log(error.response);
      toast.error(error?.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      console.log(error.response.data.message);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
