// import { createStore } from 'redux'

import { configureStore } from '@reduxjs/toolkit'
// import counterSlice from './feature/changeStateSlice'
import userSlice from './feature/user/userSlice'
import drawerSlice from "./feature/changeStateSlice";
import doctorSlice from './feature/doctors/doctorSlice';
import patientSlice from './feature/patients/patientSlice';
import adminsSlice from './feature/admins/adminsSlice';
import labSlice from './feature/laboratory/labSlice';
import pharmacySlice from './feature/pharmacy/pharmacySlice';
import dashboardSlice from './feature/dashboard/dashboardSlice';
import testOrderSlice from './feature/TestCase/testOrderSlice';
import DeliverersSlice from './feature/Deliverers/DeliverersSlice';
import CommonSlice from './feature/common/CommonSlice';
import pharmaciestSlice from './feature/pharmacist/pharmaciestSlice';
import laboratoriestSlice from './feature/laboratoriest/laboratoriestSlice';
import transactionSlice from './feature/transactions/transactionSlice';
import CategorySlice from './feature/category/CategorySlice';

const store = configureStore({
  reducer: {
    user: userSlice,
    showSideBar: drawerSlice,
    doctor: doctorSlice,
    patient: patientSlice,
    admins: adminsSlice,
    labs: labSlice,
    pharmacy: pharmacySlice,
    dashboard: dashboardSlice,
    testOrder: testOrderSlice,
    deliverers: DeliverersSlice,
    common: CommonSlice,
    pharmaciest: pharmaciestSlice,
    labTestOrder: laboratoriestSlice,
    transaction: transactionSlice,
    category: CategorySlice,
  },
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

export default store
