import { createSlice } from '@reduxjs/toolkit'
import { approveAppointments, approvePatinetConsultation, approvePatinetLabTestOrder, deleteConsultationTransaction, deleteDrugTransaction, deleteLabTestTransaction, getDrugTransactionList, getLabTestTransactionList, getTransactionList } from './transactionAction'


const initialState = {

    //consultation
    loading: false,
    transactionList: [],
    error: null,
    success: false,

    //drug order
    drugorderTransLoading: false,
    drugorderTransList: [],
    drugorderTransError: null,
    drugorderTransSuccess: false,

    //test order transaction
    testOrderTransLoading: false,
    testOrderTransList: [],
    testOrderTransError: null,
    testOrderTransSuccess: false,




    ////delete test order
    deleteTestOrderTransLoading: false,
    deleteTestOrderTransError: null,
    deleteTestOrderTransSuccess: false,

    //delete drug order
    deleteDrugOrderTransLoading: false,
    deleteDrugOrderTransError: null,
    deleteDrugOrderTransSuccess: false,

    //consultation
    deleteConsultationLoading: false,
    deleteConsultationError: null,
    deleteConsultationSuccess: false,



    ////------------------------------------------
    //all approve states
    ////------------------------------------------------------------------

    approvePatinetConsultationLoading: false,
    approvePatinetConsultationError: null,
    approvePatinetConsultationSuccess: false,


    approvePatinetLabTestOrderLoading: false,
    approvePatinetLabTestOrderError: null,
    approvePatinetLabTestOrderSuccess: false,


    ///appointmennt
    approveAppointmentsLoading: false,
    approveAppointmentsError: null,
    approveAppointmentsSuccess: false,


}
const transactionSlice = createSlice({
    name: 'transaction',
    initialState,
    reducers: {
    },
    extraReducers: {
        // get consultation transaction list
        [getTransactionList.pending]: (state) => {
            state.loading = true
        },
        [getTransactionList.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.transactionList = payload
        },
        [getTransactionList.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },

        // get drug order transaction list
        [getDrugTransactionList.pending]: (state) => {
            state.drugorderTransLoading = true
        },
        [getDrugTransactionList.fulfilled]: (state, { payload }) => {
            state.drugorderTransLoading = false
            state.drugorderTransList = payload
        },
        [getDrugTransactionList.rejected]: (state, { payload }) => {
            state.drugorderTransLoading = false
            state.drugorderTransError = payload
        },

        // get lab test order transaction list
        [getLabTestTransactionList.pending]: (state) => {
            state.testOrderTransLoading = true
        },
        [getLabTestTransactionList.fulfilled]: (state, { payload }) => {
            state.testOrderTransLoading = false
            state.testOrderTransList = payload
        },
        [getLabTestTransactionList.rejected]: (state, { payload }) => {
            state.testOrderTransLoading = false
            state.testOrderTransError = payload
        },

        ////-----------------------------------------------------------------------------------
        /// deleteConsultationTransaction
        ////----------------------------------------------------------------------------------
        [deleteConsultationTransaction.pending]: (state) => {
            state.deleteConsultationLoading = true
        },
        [deleteConsultationTransaction.fulfilled]: (state, { payload }) => {
            state.deleteConsultationLoading = false
            state.deleteConsultationSuccess = true
        },
        [deleteConsultationTransaction.rejected]: (state, { payload }) => {
            state.deleteConsultationLoading = false
            state.deleteConsultationError = payload
        },
        ////-----------------------------------------------------------------------------------
        /// deleteLabTestTransaction
        ////----------------------------------------------------------------------------------
        [deleteLabTestTransaction.pending]: (state) => {
            state.deleteTestOrderTransLoading = true

        },
        [deleteLabTestTransaction.fulfilled]: (state, { payload }) => {
            state.deleteTestOrderTransLoading = false
            state.deleteTestOrderTransSuccess = true
        },
        [deleteLabTestTransaction.rejected]: (state, { payload }) => {
            state.deleteTestOrderTransLoading = false
            state.deleteTestOrderTransError = payload
        },

        ////-----------------------------------------------------------------------------------
        /// deleteDrugTransaction
        ////----------------------------------------------------------------------------------
        [deleteDrugTransaction.pending]: (state) => {
            state.deleteDrugOrderTransLoading = true
        },
        [deleteDrugTransaction.fulfilled]: (state, { payload }) => {
            state.deleteDrugOrderTransLoading = false
            state.deleteDrugOrderTransSuccess = true
        },
        [deleteDrugTransaction.rejected]: (state, { payload }) => {
            state.deleteDrugOrderTransLoading = false
            state.deleteDrugOrderTransError = payload
        },


        ////----------------------------------------------------------
        ///approve
        ////----------------------------------------------------------- 
        [approvePatinetConsultation.pending]: (state) => {
            state.approvePatinetConsultationLoading = true
        },
        [approvePatinetConsultation.fulfilled]: (state, { payload }) => {
            state.approvePatinetConsultationLoading = false
            state.approvePatinetConsultationSuccess = true
        },
        [approvePatinetConsultation.rejected]: (state, { payload }) => {
            state.approvePatinetConsultationLoading = false
            state.approvePatinetConsultationError = payload
        },

        //
        /////approve
        // 
        [approvePatinetLabTestOrder.pending]: (state) => {
            state.approvePatinetLabTestOrderLoading = true
        },
        [approvePatinetLabTestOrder.fulfilled]: (state, { payload }) => {
            state.approvePatinetLabTestOrderLoading = false
            state.approvePatinetLabTestOrderSuccess = true
        },
        [approvePatinetLabTestOrder.rejected]: (state, { payload }) => {
            state.approvePatinetLabTestOrderLoading = false
            state.approvePatinetLabTestOrderError = payload
        },


        //
        /////approve appointment
        // 
        [approveAppointments.pending]: (state) => {
            state.approveAppointmentsLoading = true
        },
        [approveAppointments.fulfilled]: (state, { payload }) => {
            state.approveAppointmentsLoading = false
            state.approveAppointmentsSuccess = true
        },
        [approveAppointments.rejected]: (state, { payload }) => {
            state.approveAppointmentsLoading = false
            state.approveAppointmentsError = payload
        },
    },
})

export default transactionSlice.reducer