import React, { Component, Suspense } from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import "./scss/style.scss";
import ThemeProvider from "./theme";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BaseOptionChartStyle } from "./components/chart/BaseOptionChart";
import Login from "./views/Admin/pages/login/Login";
import Page404 from "./views/Admin/pages/page404/Page404";
import Page500 from "./views/Admin/pages/page500/Page500";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

// Pages

class App extends Component {
  render() {
    return (
      <ThemeProvider>
        <ToastContainer />
        <BaseOptionChartStyle />
        <HashRouter>
          <Suspense fallback={loading}>
            <Routes>
              <Route
                exact
                path="/login"
                name="Login Page"
                element={<Login />}
              />
              <Route exact path="/404" name="Page 404" element={<Page404 />} />
              <Route exact path="/500" name="Page 500" element={<Page500 />} />
              <Route path="*" name="Home" element={<DefaultLayout />} />
            </Routes>
          </Suspense>
        </HashRouter>
      </ThemeProvider>
    );
  }
}

export default App;
