import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { toast } from 'react-toastify'
// doctorAction.js
export const getAdminsList = createAsyncThunk(
    'admins/list',
    async (arg, { getState, rejectWithValue }) => {
        try {
            // get user data from store
            const { user } = getState()
            console.log(` ${user}`);
            // configure authorization header with user's token
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("userToken")}`,
                },
            }
            // console.log(localStorage.getItem("userToken"))
            const { data } = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/admins`,
                config
            )
            return data
        } catch (error) {
            console.log(error);
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)

export const addAdmin = createAsyncThunk(
    'admins/create',
    async (arg, { getState, rejectWithValue }) => {
        console.log(arg);
        try {
            // configure authorization header with user's token
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("userToken")}`,
                    // 'Content-Type': 'application/json'
                },
            }
            const data = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/admins`, arg,
                config
            )
                .then((res) => toast.success('Admin Registration success', {
                    position: toast.POSITION.BOTTOM_RIGHT
                }))
            console.log(data);
            return data
        } catch (error) {
            console.log(error);
            toast.error(error?.response?.data?.field_errors[0], {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)
export const deleteAdmin = createAsyncThunk(
    'admins/delete',
    async (arg, { getState, rejectWithValue }) => {
        console.log(arg);
        try {
            // configure authorization header with user's token
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("userToken")}`,
                },
            }
            const { data } = await axios.delete(
                `${process.env.REACT_APP_BASE_URL}/admins/${arg}`,
                config
            )
                .then((res) => {
                    if (res) {
                        toast.success('Admin Deletion success', {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });
                    }
                });


            console.log(data);
            return data
        } catch (error) {
            console.log(error);
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)

export const getLastThreeMonth = createAsyncThunk(
    'admins/getLastThreeMonth/list',
    async (arg, { getState, rejectWithValue }) => {
        try {
            // get user data from store
            const { user } = getState()
            console.log(` ${user}`);
            // configure authorization header with user's token
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("userToken")}`,
                },
            }
            // console.log(localStorage.getItem("userToken"))
            const { data } = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/appointments/last-three-month`,
                config
            )
            return data
        } catch (error) {
            console.log(error);
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)



