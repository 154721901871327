import { Error } from "@mui/icons-material";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

export const getDrugList = createAsyncThunk(
  "pharmaciest/drug/list",
  async (arg, { getState, rejectWithValue }) => {
    const { user } = getState();
    console.log(user);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      };
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/pharmacy/drugs`,
        config
      );
      return data;
    } catch (error) {
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

//accept drug orders by pharmacy
export const acceptDrugOrder = createAsyncThunk(
  "pharmaciest/drug/order/accept",
  async (arg, { getState, rejectWithValue }) => {
    const { order_id, status, delivery_fee } = arg;
    const { user } = getState();
    console.log(user);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          "Content-Type": "application/json",
        },
      };
      // const params = new URLSearchParams({
      //     order_id: id,
      //     status: statuss,
      //     delivery_fee: delivery_fee
      // }).toString();
      // const url =
      //     "${process.env.REACT_APP_BASE_URL}/pharmacies/drug-order/reply/?" +
      //     params;
      // const data = await axios.put(
      //     '${process.env.REACT_APP_BASE_URL}/pharmacies/drug-order/reply',
      //     null,
      //     {
      //         params: {
      //             order_id,
      //             status,
      //             delivery_fee
      //         }
      //     },
      //     config
      // )

      const url = `${process.env.REACT_APP_BASE_URL}/pharmacies/drug-order/reply?order_id=${order_id}&status=Accepted&delivery_fee=${delivery_fee}`;
      const data = await axios.put(url, null, config).then((res) => {
        if (res) {
          toast.success("Operation Success!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          // dispatch(getTransactionList())
        }
      });

      return data;
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.error_description, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const getDrugCategoryList = createAsyncThunk(
  "pharmaciest/drug/category/list",
  async (arg, { getState, rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      };
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/drug-categories`,
        config
      );
      return data;
    } catch (error) {
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const getPharmacyByUserId = createAsyncThunk(
  "pharmaciest/pharmacy/byid",
  async (arg, { getState, rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      };
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/pharmacies/${localStorage.getItem(
          "id"
        )}`,
        config
      );
      return data;
    } catch (error) {
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

///ADD DRUG
export const addDrug = createAsyncThunk(
  "pharmaciest/drug/creation",
  async (arg, { getState, rejectWithValue }) => {
    console.log(arg.get("name"));
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          "content-type": "multipart/form-data",
        },
      };
      const data = await axios
        .post(`${process.env.REACT_APP_BASE_URL}/pharmacy/drugs`, arg, config)
        .then((res) => {
          console.log(res);
          toast.success("Drug Registration success", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        })
        .catch(function (error) {
          console.log(error);
        });
      return data;
    } catch (error) {
      console.log(error);
      if (error?.response?.message !== "") {
        toast.error(error?.response?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
      if (error?.response?.data?.error_message !== "") {
        toast.error(error?.response?.data?.error_message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else if (error?.response?.data?.field_errors.length !== 0) {
        toast.error(error?.response?.data?.field_errors[0], {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

///DELETE DRUG
export const deleteDrug = createAsyncThunk(
  "pharmaciest/drug/delete/",
  async (arg, { getState, rejectWithValue, dispatch }) => {
    try {
      console.log(arg);
      const data = await axios
        .delete(`${process.env.REACT_APP_BASE_URL}/pharmacy/drugs/${arg}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          dispatch(getDrugList());
          console.log(res);
          toast.success("Drug Deleted", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        });
      return data;
    } catch (error) {
      console.log(Error);
      toast.error(error.response?.data?.error_description, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
///DELETE DRUG ORDER
export const deleteDrugOrderByPharmacist = createAsyncThunk(
  "pharmaciest/deleteDrugOrderByPharmacist/delete/",
  async (arg, { getState, rejectWithValue }) => {
    try {
      console.log(arg);
      const data = await axios
        .delete(
          `${process.env.REACT_APP_BASE_URL}/pharmacy/drug-orders/${arg}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log(res);
          toast.success("Operation Success!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        });
      return data;
    } catch (error) {
      console.log(Error);
      toast.error(error.response?.data?.error_description, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

///GET drug order
export const getAllDrugOrderList = createAsyncThunk(
  "pharmaciest/drug/order/list",
  async (arg, { getState, rejectWithValue }) => {
    try {
      console.log(arg);
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/pharmacy/drug-orders`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
            "Content-Type": "application/json",
          },
        }
      );
      // .then((res) => {
      console.log(data);

      // }).catch(function (error) {
      //         console.log(error);
      //     });
      return data;
    } catch (error) {
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
