import { createSlice } from '@reduxjs/toolkit'
import { addDoctorCat, addDrugCategory, addHomecareEmergencyCat, addTestCategory, deleteDrugCategory, deleteTestCategory, drugCategoryList, getDoctorCatList, getHomecareEmergencyCatList, testCategoryList } from './CategoryAction';


const initialState = {

    ///drug category list
    drugCatlistLoading: false,
    drugCatlist: [],
    drugCatlistError: false,
    drugCatlistSuccess: false,

    ///drug category add
    drugCatAddLoading: false,
    drugCatAdd: [],
    drugCatAddError: false,
    drugCatAddSuccess: false,
    ///test Add
    testCatAddLoading: false,
    testCatAddError: false,
    testCatAddSuccess: false,
    ///test list
    testCatlistLoading: false,
    testCatlist: [],
    testCatlistError: false,
    testCatlistSuccess: false,

    ///delete test category
    deleteTestCatLoading: false,
    deleteTestCatError: false,
    deleteTestCatSuccess: false,

    ///delete drug category
    deleteDrugCatLoading: false,
    deleteDrugCatError: false,
    deleteDrugCatSuccess: false,

    ///homecare category  add
    homecareAddLoading: false,
    homecareAddError: null,
    homecareAddSuccess: false,

    getHomecareCatLoading: false,
    getHomecareCatError: null,
    homeCareCat: [],
    getHomecareCatSuccess: false,


    ///DOCTOR category
    addDoctorCatLoading: false,
    addDoctorCatError: null,
    addDoctorCatSuccess: false,

    getdoctorCatLoading: false,
    getdoctorCatError: null,
    doctorCatList: [],
    getdoctorCatSuccess: false,

}


const CategorySlice = createSlice({
    name: 'category',
    initialState,
    reducers: {
    },
    extraReducers: {



        // ADD HOME CARE SLICE
        [addHomecareEmergencyCat.pending]: (state) => {
            state.homecareAddLoading = true
        },
        [addHomecareEmergencyCat.fulfilled]: (state, { payload }) => {
            state.homecareAddLoading = false
            state.homecareAddSuccess = true;
        },
        [addHomecareEmergencyCat.rejected]: (state, { payload }) => {
            state.homecareAddLoading = false
            state.homecareAddError = payload
        },

        ///getHomeCarecategory List
        [getHomecareEmergencyCatList.pending]: (state) => {
            state.getHomecareCatLoading = true
        },
        [getHomecareEmergencyCatList.fulfilled]: (state, { payload }) => {
            state.getHomecareCatLoading = false
            state.homeCareCat = payload
            state.getHomecareCatSuccess = true;
        },
        [getHomecareEmergencyCatList.rejected]: (state, { payload }) => {
            state.getHomecareCatLoading = false
            state.getHomecareCatError = payload
        },
        /////--------------------------------------------------------------------
        //DOCOTR CATEGORY(SPECIALITY) PART
        ////////-------------------------------------------------------------------
        //ADD DOCTOR CATEGORY
        [addDoctorCat.pending]: (state) => {
            state.addDoctorCatLoading = true
        },
        [addDoctorCat.fulfilled]: (state, { payload }) => {
            state.addDoctorCatLoading = false
            state.addDoctorCatSuccess = true;
        },
        [addDoctorCat.rejected]: (state, { payload }) => {
            state.addDoctorCatLoading = false
            state.addDoctorCatError = payload
        },

        ///GET DOCOTR CATEGORY LIST
        [getDoctorCatList.pending]: (state) => {
            state.getdoctorCatLoading = true
        },
        [getDoctorCatList.fulfilled]: (state, { payload }) => {
            console.log(payload);
            state.getdoctorCatLoading = false
            state.doctorCatList = payload
            state.getdoctorCatSuccess = true;
        },
        [getDoctorCatList.rejected]: (state, { payload }) => {
            state.getdoctorCatLoading = false
            state.getdoctorCatError = payload
        },


        // delete test cat
        [deleteTestCategory.pending]: (state) => {
            state.deleteTestCatLoading = true
        },
        [deleteTestCategory.fulfilled]: (state, { payload }) => {
            state.deleteTestCatLoading = false
            state.deleteTestCatSuccess = true
        },
        [deleteTestCategory.rejected]: (state, { payload }) => {
            state.deleteTestCatLoading = false
            state.deleteTestCatError = true
        },

        // delete drug cat
        [deleteDrugCategory.pending]: (state) => {
            state.deleteDrugCatLoading = true
        },
        [deleteDrugCategory.fulfilled]: (state, { payload }) => {
            state.deleteDrugCatLoading = false
            state.deleteDrugCatSuccess = true
        },
        [deleteDrugCategory.rejected]: (state, { payload }) => {
            state.deleteDrugCatLoading = false
            state.deleteDrugCatError = true
        },


        // add test cat
        [addTestCategory.pending]: (state) => {
            state.testCatAddLoading = true
        },
        [addTestCategory.fulfilled]: (state, { payload }) => {
            state.testCatAddLoading = false
            state.testCatAddSuccess = true
        },
        [addTestCategory.rejected]: (state, { payload }) => {
            state.testCatAddLoading = false
            state.testCatAddError = true
        },




        // test cat list
        [testCategoryList.pending]: (state) => {
            state.testCatlistLoading = true
        },
        [testCategoryList.fulfilled]: (state, { payload }) => {
            state.testCatlistLoading = false
            state.testCatlist = payload
        },
        [testCategoryList.rejected]: (state, { payload }) => {
            state.testCatlistLoading = false
            state.testCatlistError = true
        },

        // add drug cat
        [addDrugCategory.pending]: (state) => {
            state.drugCatAddLoading = true
        },
        [addDrugCategory.fulfilled]: (state, { payload }) => {
            state.drugCatAddLoading = false
            state.drugCatAdd = payload
        },
        [addDrugCategory.rejected]: (state, { payload }) => {
            state.drugCatAddLoading = false
            state.drugCatAddError = true
        },


        // drug cat list
        [drugCategoryList.pending]: (state) => {
            state.drugCatlistLoading = true
        },
        [drugCategoryList.fulfilled]: (state, { payload }) => {
            state.drugCatlistLoading = false
            state.drugCatlist = payload
        },
        [drugCategoryList.rejected]: (state, { payload }) => {
            state.drugCatlistLoading = false
            state.drugCatlistError = true
        },
    },
})

export default CategorySlice.reducer