import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
// userAction.js
export const userLogin = createAsyncThunk(
  // action type string
  "user/login",
  // callback function
  async (cred, { rejectWithValue }) => {
    console.log("login fired", cred);
    try {
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      console.log(cred);

      // make request to backend
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/auth/login`,
        cred,
        config
      );
      console.log("user ID from action: " + data.user_id);
      // store user's token in local storage
      localStorage.setItem("userToken", data.access_token);
      localStorage.setItem("role", data.role);
      localStorage.setItem("id", data.user_id);
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        console.log(error.response.data.message);
        return rejectWithValue(error.response.data.message);
      } else {
        // console.log(error.message);
        return rejectWithValue(error.message);
      }
    }
  }
);

export const registerUser = createAsyncThunk(
  "user/register",
  async ({ firstName, email, password }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      await axios.post(
        "/api/user/register",
        { firstName, email, password },
        config
      );
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getUserDetails = createAsyncThunk(
  "user/getUserDetails",
  async (arg, { getState, rejectWithValue }) => {
    try {
      // get user data from store
      const { user } = getState();

      // configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${user.userToken}`,
        },
      };

      const { data } = await axios.get(`/api/user/profile`, config);
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getUserById = createAsyncThunk(
  "user/getUserbyID",
  async (arg, { getState, rejectWithValue }) => {
    console.log("USER ID FROM GETUSERBY ID:  " + arg);
    try {
      // get user data from store
      const { user } = getState();
      // configure authorization header with user's token
      console.log(user.userToken);
      const config = {
        headers: {
          Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoxLCJhdXRob3JpemVkIjp0cnVlLCJ1c2VybmFtZSI6IndvcmtuZWhAeWFsYSIsImV4cCI6MTY2Mzc0MjEwMn0.5jF1kJiW-pcO0wTgjWrfa8bWvd8RYtjlUJmk6AOulz8`,
        },
      };
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/users/${arg}`,
        config
      );
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
