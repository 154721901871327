import { createSlice } from '@reduxjs/toolkit'
import { deleteTestOrder, getTestOrderList } from './testOrderAction'


const initialState = {
    loading: false,
    testOrder: [],
    error: null,
    success: false,


    deleteLoading: false,
    deleteError: null,
    deleteSuccess: false,
}
const testOrderSlice = createSlice({
    name: 'testOrder',
    initialState,
    reducers: {
    },
    extraReducers: {
        // get testOrder list
        [getTestOrderList.pending]: (state) => {
            state.loading = true
        },
        [getTestOrderList.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.testOrder = payload
        },
        [getTestOrderList.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },
        
        // delete testOrder
        [deleteTestOrder.pending]: (state) => {
            state.deleteLoading = true
        },
        [deleteTestOrder.fulfilled]: (state, { payload }) => {
            state.deleteLoading = false
            state.deleteSuccess = false
        },
        [deleteTestOrder.rejected]: (state, { payload }) => {
            state.deleteLoading = false
            state.deleteError = payload
        },
    },
})

export default testOrderSlice.reducer