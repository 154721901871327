import { createSlice } from '@reduxjs/toolkit'
import { addPatient, getPatientList } from './patientAction';


const initialState = {
    loading: false,
    patients: [],
    error: null,
    success: false,

    //add patient
    addPatientLoading: false,
    addPatientError: null,
    addPatientSuccess: false,
}
const patientSlice = createSlice({
    name: 'patient',
    initialState,
    reducers: {
    },
    extraReducers: {

        // get patient list
        [getPatientList.pending]: (state) => {
            state.loading = true
        },
        [getPatientList.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.patients = payload
        },
        [getPatientList.rejected]: (state, { payload }) => {
            state.loading = false

        },
        // Add patient
        [addPatient.pending]: (state) => {
            state.addPatientLoading = true
        },
        [addPatient.fulfilled]: (state, { payload }) => {
            state.addPatientLoading = false
            state.addPatientSuccess = true
        },
        [addPatient.rejected]: (state, { payload }) => {
            state.addPatientLoading = false
            state.addPatientError = true

        },
    },
})

export default patientSlice.reducer