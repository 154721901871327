import { createSlice } from '@reduxjs/toolkit'
import { getdashboardList, getLastMonthConsultedList, getLastMonthNewUserRegistered } from './dashboardAction';


const initialState = {
    loading: false,
    dashboard: {},
    error: null,
    success: false,

    //for new registed user
    getLastMonthNewUserloading: false,
    getLastMonthNewUser: [],
    getLastMonthNewUsererror: null,
    getLastMonthNewUsersuccess: false,

    //for getLastMonthConsulted 
    getLastMonthConsultedloading: false,
    getLastMonthConsulted: [],
    getLastMonthConsultederror: null,
    getLastMonthConsultedsuccess: false,
}
const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
    },
    extraReducers: {

        // get dashboard list
        [getdashboardList.pending]: (state) => {
            console.log('dashboard data list fetch loading...');
            state.loading = true
        },
        [getdashboardList.fulfilled]: (state, { payload }) => {
            console.log('dashboard data list fetched...');
            state.loading = false
            state.dashboard = payload
        },
        [getdashboardList.rejected]: (state, { payload }) => {
            console.log('dashboard data list rejected...');
            state.loading = false

        },

        // get getLastMonthNewUserRegistered
        [getLastMonthNewUserRegistered.pending]: (state) => {

            state.getLastMonthNewUserloading = true
        },
        [getLastMonthNewUserRegistered.fulfilled]: (state, { payload }) => {
            console.log(payload);
            state.getLastMonthNewUserloading = false
            state.getLastMonthNewUser = payload
        },
        [getLastMonthNewUserRegistered.rejected]: (state, { payload }) => {
            state.getLastMonthNewUsererror = payload
            state.getLastMonthNewUserloading = false

        },

        // get getLastMonthConsulted
        [getLastMonthConsultedList.pending]: (state) => {
            state.getLastMonthConsultedloading = true
        },
        [getLastMonthConsultedList.fulfilled]: (state, { payload }) => {
            console.log(payload);
            state.getLastMonthConsultedloading = false
            state.getLastMonthConsulted = payload
        },
        [getLastMonthConsultedList.rejected]: (state, { payload }) => {
            state.getLastMonthConsultederror = payload
            state.getLastMonthConsultedloading = false

        },
    },
})

export default dashboardSlice.reducer