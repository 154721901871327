import { createSlice } from '@reduxjs/toolkit'
import { addPharmacy, deleteDrugOrder, deletePharmacy, getDrugOrderList, getPendingPharmacyList, getPharmacyDetailById, getPharmacyList } from './pharmacyAction'


const initialState = {
    loading: false,
    pharmacy: [],
    error: null,
    success: false,


    orderListLoading: false,
    orderList: [],
    orderListError: null,
    orderListSuccess: false,

    //delete drug order
    deleteDrugOrderLoading: false,
    deleteDrugOrderError: null,
    deleteDrugOrderSuccess: false,

    //delete pharmacy
    deletePharmacyLoading: false,
    deletePharmacyError: null,
    deletePharmacySuccess: false,


    //Add pharmacy
    addPharmacyLoading: false,
    addPharmacyError: null,
    addPharmacySuccess: false,


    //get pharmacy By ID
    getPharmacyByIdLoading: false,
    getPharmacyByIdError: null,
    getPharmacyByIdSuccess: false,
    getPharmacyBy: {},

    //for pending
    pendingPharmacylist: {}
}
const pharmacySlice = createSlice({
    name: 'pharmacy',
    initialState,
    reducers: {
    },
    extraReducers: {
        // get pharmacy list
        [getPharmacyList.pending]: (state) => {
            state.loading = true
        },
        [getPharmacyList.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.pharmacy = payload
        },
        [getPharmacyList.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },

        // get pharmacy by id
        [getPharmacyDetailById.pending]: (state) => {
            state.getPharmacyByIdLoading = true
        },
        [getPharmacyDetailById.fulfilled]: (state, { payload }) => {
            state.getPharmacyByIdLoading = false
            state.getPharmacyBy = payload
        },
        [getPharmacyDetailById.rejected]: (state, { payload }) => {
            state.getPharmacyByIdLoading = false
            state.getPharmacyByIdError = payload
        },


        // add pharmacy list
        [addPharmacy.pending]: (state) => {
            state.addPharmacyLoading = true
        },
        [addPharmacy.fulfilled]: (state, { payload }) => {
            state.addPharmacyLoading = false
            state.addPharmacySuccess = true
        },
        [addPharmacy.rejected]: (state, { payload }) => {
            state.addPharmacyLoading = false
            state.addPharmacyError = payload
        },


        // delete pharmacy
        [deletePharmacy.pending]: (state) => {
            state.deletePharmacyLoading = true
        },
        [deletePharmacy.fulfilled]: (state, { payload }) => {
            state.deletePharmacyLoading = false
            state.deletePharmacySuccess = true
        },
        [deletePharmacy.rejected]: (state, { payload }) => {
            state.deletePharmacyLoading = false
            state.deletePharmacyError = payload
        },

        ////-----------------------------------------------------------------------------------------
        // get Pending pharmacy list
        ////-----------------------------------------------------------------------------------------

        [getPendingPharmacyList.pending]: (state) => {
            state.loading = true
        },
        [getPendingPharmacyList.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.pendingPharmacylist = payload
        },
        [getPendingPharmacyList.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },

        // get PHARMACY DRUG ORDER list
        [getDrugOrderList.pending]: (state) => {
            state.orderListLoading = true
        },
        [getDrugOrderList.fulfilled]: (state, { payload }) => {
            state.orderListLoading = false
            state.orderList = payload
        },
        [getDrugOrderList.rejected]: (state, { payload }) => {
            state.orderListLoading = false
            state.orderListError = payload
        },

        // delete PHARMACY DRUG ORDER
        [deleteDrugOrder.pending]: (state) => {
            state.deleteDrugOrderLoading = true
        },
        [deleteDrugOrder.fulfilled]: (state, { payload }) => {
            state.deleteDrugOrderLoading = false
            state.deleteDrugOrderSuccess = true
        },
        [deleteDrugOrder.rejected]: (state, { payload }) => {
            state.deleteDrugOrderLoading = false
            state.deleteDrugOrderError = payload
        },
    },
})

export default pharmacySlice.reducer