import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
// get reciept for consultation
export const getTransactionList = createAsyncThunk(
  "admin/consultation/transaction/list",
  async (arg, { getState, rejectWithValue }) => {
    try {
      // get user data from store
      // const { user } = getState()
      // console.log(`Bearer ${localStorage.getItem("userToken")}`);
      // configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      };
      // console.log(localStorage.getItem("userToken"))
      const { data } = await axios.get(
        `http://165.227.82.66:8123/api/v1/patients/bank-receipt-for-consultancy-payment`,
        config
      );
      return data;
    } catch (error) {
      toast.error(error.response?.data?.error_description, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
// delete reciept for consultation
export const deleteConsultationTransaction = createAsyncThunk(
  "admin/consultation/transaction/delete",
  async (arg, { getState, rejectWithValue, dispatch }) => {
    try {
      // get user data from store
      // const { user } = getState()
      // console.log(`Bearer ${localStorage.getItem("userToken")}`);
      // configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      };
      // console.log(localStorage.getItem("userToken"))
      const { data } = await axios
        .delete(
          `http://165.227.82.66:8123/api/v1/patients/bank-receipt-for-consultancy-payment/${arg}`,
          config
        )
        .then((res) => {
          if (res) {
            toast.success("Deletion success", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
          dispatch(getTransactionList());
        });
      return data;
    } catch (error) {
      toast.error(error.response?.data?.error_description, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
// get reciept for drug order.js
export const getDrugTransactionList = createAsyncThunk(
  "admin/drug/transaction/list",
  async (arg, { getState, rejectWithValue }) => {
    try {
      // get user data from store
      // const { user } = getState()
      // console.log(`Bearer ${localStorage.getItem("userToken")}`);
      // configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      };
      // console.log(localStorage.getItem("userToken"))
      const { data } = await axios.get(
        `http://165.227.82.66:8123/api/v1/patients/bank-receipt-for-drug-payment`,
        config
      );

      return data;
    } catch (error) {
      toast.error(error.response?.data?.error_description, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
// delete reciept for drug order.js
export const deleteDrugTransaction = createAsyncThunk(
  "admin/drug/transaction/delete",
  async (arg, { getState, rejectWithValue, dispatch }) => {
    try {
      // get user data from store
      // const { user } = getState()
      // console.log(`Bearer ${localStorage.getItem("userToken")}`);
      // configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      };
      // console.log(localStorage.getItem("userToken"))
      const { data } = await axios
        .delete(
          `http://165.227.82.66:8123/api/v1/patients/bank-receipt-for-drug-payment/${arg}`,
          config
        )
        .then((res) => {
          if (res) {
            toast.success("Deletion success", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
          dispatch(getDrugTransactionList());
        });

      return data;
    } catch (error) {
      toast.error(error.response?.data?.error_description, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
// get reciept for lab test
export const getLabTestTransactionList = createAsyncThunk(
  "admin/labTest/transaction/list",
  async (arg, { getState, rejectWithValue }) => {
    try {
      // get user data from store
      // const { user } = getState()
      // console.log(`Bearer ${localStorage.getItem("userToken")}`);
      // configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      };
      // console.log(localStorage.getItem("userToken"))
      const { data } = await axios.get(
        `http://165.227.82.66:8123/api/v1/admin/bank-receipt-for-laboratory-test-payment`,
        config
      );

      return data;
    } catch (error) {
      console.log(error);
      toast.error(error.response?.data?.error_description, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
// delete reciept for lab test
export const deleteLabTestTransaction = createAsyncThunk(
  "admin/labTest/transaction/delete",
  async (arg, { getState, rejectWithValue, dispatch }) => {
    try {
      // get user data from store
      // const { user } = getState()
      // console.log(`Bearer ${localStorage.getItem("userToken")}`);
      // configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      };
      // console.log(localStorage.getItem("userToken"))
      const { data } = await axios
        .delete(
          `http://165.227.82.66:8123/api/v1/bank-receipt-for-laboratory-test-payment/${arg}`,
          config
        )
        .then((res) => {
          if (res) {
            toast.success("Deletion success", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
          dispatch(deleteLabTestTransaction());
        });
      return data;
    } catch (error) {
      console.log(error);
      toast.error(error.response?.data?.error_description, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

///Approve payament for petient consultation
export const approvePatinetConsultation = createAsyncThunk(
  "approvePatinetConsultation",
  async (arg, { getState, rejectWithValue, dispatch }) => {
    const { id } = arg;
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          // 'Content-Type': 'application/json',
        },
      };

      const url = `http://165.227.82.66:8123/api/v1/admins/approve-patient-consultancy-payment?id=${id}&payment_status=Active`;
      console.log("====================================");
      console.log(url);
      console.log("====================================");
      const data = await axios.put(url, null, config).then((res) => {
        if (res) {
          toast.success("Operation Success!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          dispatch(getTransactionList());
        }
      });
      console.log(data);
      return data;
    } catch (error) {
      console.log(error);
      toast.error(error.response?.data?.error_description, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
///Approve patient-laboratory-test-order-payment
export const approvePatinetLabTestOrder = createAsyncThunk(
  "patient-laboratory-test-order-payment",
  async (arg, { getState, rejectWithValue, dispatch }) => {
    const { id, payment_status } = arg;
    try {
      // configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      };
      // console.log(localStorage.getItem("userToken"))

      const url = `http://165.227.82.66:8123/api/v1/admins/patient-laboratory-test-order-payment?id=${id}&payment_status=${payment_status}`;
      const data = await axios.put(url, null, config).then((res) => {
        if (res) {
          toast.success("Operation Success!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
        dispatch(getLabTestTransactionList());
      });

      return data;
    } catch (error) {
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

///Approve appointments
export const approveAppointments = createAsyncThunk(
  "approve-appointments",
  async (arg, { getState, rejectWithValue }) => {
    try {
      // configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      };
      console.log("coming arguments:", arg);
      const { data } = await axios.put(
        `http://165.227.82.66:8123/api/v1/admins/approve-appointments?id=${arg}&payment_status=Active`,
        config
      );
      return data;
    } catch (error) {
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);