import { createSlice } from '@reduxjs/toolkit'
import { acceptRejectDoctor, addDoctor, deleteDoctor, getDoctorsList, getPendingDoctorsList } from './doctorAction'


const initialState = {
    loading: false,
    doctors: [],
    error: null,
    success: false,


    //add doctor states
    AddDocLoading: false,
    AddDocError: null,
    AddDocSuccess: false,

    //delete doctor
    deleteDocLoading: false,
    deleteDocError: null,
    deleteDocSuccess: false,

    //accept /reject state
    acc_rej_loading: false,
    acc_rej_error: null,
    acc_rej_success: false,


    ///Pending doctors list
    PendingDoctors: {},
}
const doctorSlice = createSlice({
    name: 'doctor',
    initialState,
    reducers: {
    },
    extraReducers: {


        ////-----------------------------------------------------------------------------------------
        // ADD doctor 
        ////-----------------------------------------------------------------------------------------

        [addDoctor.pending]: (state) => {
            state.AddDocLoading = true
        },
        [addDoctor.fulfilled]: (state, { payload }) => {
            state.AddDocLoading = false
            state.AddDocSuccess = true
        },
        [addDoctor.rejected]: (state, { payload }) => {
            state.AddDocLoading = false

        },

        ////-----------------------------------------------------------------------------------------
        // get doctor list
        ////-----------------------------------------------------------------------------------------

        [getDoctorsList.pending]: (state) => {
            state.loading = true
        },
        [getDoctorsList.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.doctors = payload
        },
        [getDoctorsList.rejected]: (state, { payload }) => {
            state.loading = false

        },




        ////-----------------------------------------------------------------------------------------
        // get Pending doctor list
        ////-----------------------------------------------------------------------------------------

        [getPendingDoctorsList.pending]: (state) => {
            state.loading = true
        },
        [getPendingDoctorsList.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.PendingDoctors = payload
        },
        [getPendingDoctorsList.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload

        },

        ////-----------------------------------------------------------------------------------------
        // Accept/reject Pending request
        ////-----------------------------------------------------------------------------------------

        [acceptRejectDoctor.pending]: (state) => {
            state.acc_rej_loading = true
        },
        [acceptRejectDoctor.fulfilled]: (state, { payload }) => {
            console.log(payload);
            state.acc_rej_loading = false
            state.acc_rej_success = payload
        },
        [acceptRejectDoctor.rejected]: (state, { payload }) => {
            state.acc_rej_loading = false
            state.acc_rej_error = payload
        },

        ////-----------------------------------------------------------------------------------------
        // delete doctor request
        ////-----------------------------------------------------------------------------------------

        [deleteDoctor.pending]: (state) => {
            state.deleteDocLoading = true
        },
        [deleteDoctor.fulfilled]: (state, { payload }) => {
            console.log(payload);
            state.deleteDocLoading = false
            state.deleteDocSuccess = payload
        },
        [deleteDoctor.rejected]: (state, { payload }) => {
            state.deleteDocLoading = false
            state.deleteDocError = payload
        },
    },
})

export default doctorSlice.reducer