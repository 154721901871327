import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const getdashboardList = createAsyncThunk(
    'dashboard/list',
    async (arg, { getState, rejectWithValue }) => {
        try {
            // get user data from store
            const { user } = getState()
            // console.log(`Bearer ${localStorage.getItem("userToken")}`);
            // configure authorization header with user's token
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("userToken")}`,
                },
            }
            const { data } = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/admins/dashboard-count-data`,
                config
            )

            return data
        } catch (error) {
            console.log(error);
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)
export const getLastMonthNewUserRegistered = createAsyncThunk(
    'dashboard/getmonthly/registered',
    async (arg, { getState, rejectWithValue }) => {
        try {
            // get user data from store
            const { user } = getState()
            // console.log(`Bearer ${localStorage.getItem("userToken")}`);
            // configure authorization header with user's token
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("userToken")}`,
                },
            }
            const { data } = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/users/last-month-new-user-registered/filter?month=6`,
                config
            )

            return data
        } catch (error) {
            console.log(error);
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)
export const getLastMonthConsultedList = createAsyncThunk(
    'dashboard/getmonthly/consulted',
    async (arg, { getState, rejectWithValue }) => {
        try {
            // get user data from store
            const { user } = getState()
            // console.log(`Bearer ${localStorage.getItem("userToken")}`);
            // configure authorization header with user's token
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("userToken")}`,
                },
            }
            const { data } = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/appointments/last-month-new-user-registered/filter?month=6`,
                config
            )
            return data
        } catch (error) {
            console.log(error);
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)