import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { toast } from 'react-toastify'
// Deliverers.js
export const getDeliverersList = createAsyncThunk(
    'deliverers/list',
    async (arg, { getState, rejectWithValue }) => {
        try {
            // get user data from store
            const { user } = getState()
            console.log(` ${user}`);
            // configure authorization header with user's token
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("userToken")}`,
                },
            }
            // console.log(localStorage.getItem("userToken"))
            const { data } = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/deliverers`,
                config
            )

            return data
        } catch (error) {
            console.log(error);
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)
export const addDeliverers = createAsyncThunk(
    'deliverers/createe',
    async (arg, { getState, rejectWithValue }) => {
        console.log(arg);
        try {
            // configure authorization header with user's token
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("userToken")}`,
                    'Content-Type': 'application/json'
                },
            }
            console.log(JSON.stringify({ "user": arg }));
            const data = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/deliverers`, { "user": arg },
                config
            )
                .then((res) => toast.success('Deliverers Registration success', {
                    position: toast.POSITION.BOTTOM_RIGHT
                }))
            console.log(data);
            return data
        } catch (error) {
            console.log(error);
            toast.error(error?.response?.data?.field_errors[0], {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)
export const deleteDeliverers = createAsyncThunk(
    'deliverers/delete',
    async (arg, { getState, rejectWithValue }) => {
        console.log(arg);
        // try {
        // configure authorization header with user's token
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("userToken")}`,
            },
        }
        const { data } = await axios.delete(
            `${process.env.REACT_APP_BASE_URL}/deliverers/${arg}`,
            config
        )
            .then((res) => {
                if (res) {
                    toast.success('Deliverers Deletion success', {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });

                }
            })
            .catch(function (error) {
                console.log(error);
                toast.error('Something went wrong, please try again', {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    // console.log(error.response?.data?.field_errors[0]);
                    console.log(error.response.status);
                    // console.log(error.response.headers);
                    toast.error(error?.response?.data, {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
            });
        console.log(data);
        return data
        // } catch (error) {
        //     console.log(error);
        //     toast.error('Something Went Wrong in deliverer deletion,please try again', {
        //         position: toast.POSITION.BOTTOM_RIGHT
        //     });
        //     if (error.response && error.response.data.message) {
        //         return rejectWithValue(error.response.data.message)
        //     } else {
        //         return rejectWithValue(error.message)
        //     }
        // }
    }
)
export const updateDeliverers = createAsyncThunk(
    'deliverers/update',
    async (arg, { getState, rejectWithValue }) => {
        console.log(arg);
        try {
            // configure authorization header with user's token
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("userToken")}`,
                },
            }
            const { data } = await axios.put(
                `${process.env.REACT_APP_BASE_URL}/deliverers/${arg.id}`, arg.data,
                config
            )
                .then((res) => {
                    if (res) {
                        toast.success('Deliverers Update success', {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });
                    }
                });
            console.log(data);
            return data
        } catch (error) {
            console.log(error);
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)
export const getPendingDeliverersList = createAsyncThunk(
    'PendingDeliverers/list',
    async (arg, { getState, rejectWithValue }) => {
        try {
            // get user data from store
            const { user } = getState()
            console.log(` ${user}`);
            // configure authorization header with user's token
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("userToken")}`,
                },
            }
            // console.log(localStorage.getItem("userToken"))
            const { data } = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/deliverers/pending`,
                config
            )
            return data
        } catch (error) {
            console.log(error);
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)
