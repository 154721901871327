import { createSlice } from '@reduxjs/toolkit'
import { getUserById, getUserDetails, registerUser, userLogin } from './userActions'

// initialize userToken from local storage
const userToken = localStorage.getItem('userToken')
  ? localStorage.getItem('userToken')
  : null
const role = localStorage.getItem('role')
  ? localStorage.getItem('role')
  : null
const userId = localStorage.getItem('id')
  ? localStorage.getItem('id')
  : null

const initialState = {
  loading: false,
  userInfo: null,
  userId,
  userToken,
  error: null,
  success: false,
  role,
}
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.removeItem('userToken') // delete token from storage
      localStorage.removeItem('id') // delete token from storage
      localStorage.removeItem('role') // delete token from storage
      state.loading = false
      state.userInfo = null
      state.userToken = null
      state.error = null
    },
  },
  extraReducers: {
    // login user
    [userLogin.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [userLogin.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.userInfo = payload
      state.userId = payload.user_id
      state.userToken = payload.userToken
      state.role = payload.role
    },
    [userLogin.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },

    // register user
    [getUserById.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [getUserById.fulfilled]: (state, { payload }) => {
      console.log(payload);
      state.loading = false
      // state.role = payload // registration successful
    },
    [getUserById.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    // get user details
    [getUserDetails.pending]: (state) => {
      state.loading = true
    },
    [getUserDetails.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.userInfo = payload
    },
    [getUserDetails.rejected]: (state, { payload }) => {
      state.loading = false
    },
  },
})

export const { logout } = userSlice.actions

export default userSlice.reducer