import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { toast } from 'react-toastify'
// patientsAction.js
export const getPharmacyList = createAsyncThunk(
    'pharmacy/list',
    async (arg, { getState, rejectWithValue }) => {
        try {
            // get user data from store
            // const { user } = getState()
            // console.log(`Bearer ${localStorage.getItem("userToken")}`);
            // configure authorization header with user's token
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("userToken")}`,
                },
            }
            // console.log(localStorage.getItem("userToken"))
            const { data } = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/pharmacies`,
                config
            )

            return data
        } catch (error) {
            console.log(error);
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)
// get one pharmacy detail.js
export const getPharmacyDetailById = createAsyncThunk(
    'getPharmacyDetailById/',
    async (arg, { getState, rejectWithValue }) => {
        try {
            // get user data from store
            // const { user } = getState()
            // console.log(`Bearer ${localStorage.getItem("userToken")}`);
            // configure authorization header with user's token
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("userToken")}`,
                },
            }
            // console.log(localStorage.getItem("userToken"))
            const { data } = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/pharmacies/${arg}`,
                config
            )

            return data
        } catch (error) {
            console.log(error);
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)


///GET drug order FOR ALL PHARMACY
export const getDrugOrderList = createAsyncThunk(
    'ADMIN/drug/order/list',
    async (arg, { getState, rejectWithValue }) => {
        try {
            console.log(arg);
            const { data } = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/drug-orders`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
                        'Content-Type': 'application/json'
                    },
                }
            )
            // .then((res) => {
            console.log(data);

            // }).catch(function (error) {
            //         console.log(error);
            //     });
            return data;
        } catch (error) {
            console.log(error);
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }

    }
)

export const getPendingPharmacyList = createAsyncThunk(
    'PendingPharmacy/list',
    async (arg, { getState, rejectWithValue }) => {
        try {
            // get user data from store
            const { user } = getState()
            console.log(` ${user}`);
            // configure authorization header with user's token
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("userToken")}`,
                },
            }
            // console.log(localStorage.getItem("userToken"))
            const { data } = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/pharmacies/pending`,
                config
            )
            return data
        } catch (error) {
            console.log(error);
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)

export const deletePharmacy = createAsyncThunk(
    'onePharmacy/delete',
    async (arg, { getState, rejectWithValue }) => {
        try {
            // get user data from store
            const { user } = getState()
            console.log(` ${user}`);
            // configure authorization header with user's token
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("userToken")}`,
                },
            }
            // console.log(localStorage.getItem("userToken"))
            const { data } = await axios.delete(
                `${process.env.REACT_APP_BASE_URL}/pharmacies/${arg}`,
                config
            )
                .then((res) => {
                    if (res) {
                        toast.success('Deletion success', {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });

                    }
                })
            return data
        } catch (error) {
            console.log(error);
            toast.error(error?.response?.data?.error_description, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)
export const deleteDrugOrder = createAsyncThunk(
    'drug/order/delete',
    async (arg, { getState, rejectWithValue }) => {
        try {
            // get user data from store
            const { user } = getState()
            console.log(` ${user}`);
            // configure authorization header with user's token
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("userToken")}`,
                },
            }
            // console.log(localStorage.getItem("userToken"))
            const { data } = await axios.delete(
                `${process.env.REACT_APP_BASE_URL}/drug-orders/${arg}`,
                config
            )
                .then((res) => {
                    if (res) {
                        toast.success('Deletion success', {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });

                    }
                })
            return data
        } catch (error) {
            console.log(error);
            //for internal server error
            if (error?.response?.data === "") {
                toast.error(error?.response?.statusText, {
                    position: toast.POSITION.BOTTOM_RIGHT
                })
            } else {
                toast.error(error?.response?.data?.error_description, {
                    position: toast.POSITION.BOTTOM_RIGHT
                })
            }
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)
export const addPharmacy = createAsyncThunk(
    'adminpharma/add',
    async (arg, { getState, rejectWithValue }) => {
        try {
            // get user data from store
            const { user } = getState()
            console.log(` ${user}`);
            // configure authorization header with user's token
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("userToken")}`,
                },
            }
            // console.log(localStorage.getItem("userToken"))
            const { data } = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/pharmacies`, arg,
                config
            )
                .then((res) => {
                    if (res) {
                        toast.success('pharmacy add success', {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });

                    }
                })
            return data
        } catch (error) {
            console.log(error);
            if (error?.response?.data === "") {
                toast.error(error?.response?.statusText, {
                    position: toast.POSITION.BOTTOM_RIGHT
                })
            }
            else if (error?.response?.data?.field_errors.length !== 0) {
                toast.error(error?.response?.data?.field_errors[0], {
                    position: toast.POSITION.BOTTOM_RIGHT
                })
            }
            else {
                toast.error(error?.response?.data?.error_description, {
                    position: toast.POSITION.BOTTOM_RIGHT
                })
            }
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)
