import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { toast } from 'react-toastify'
// patientsAction.js
export const getTestOrderList = createAsyncThunk(
    'lab/testOrder/list',
    async (arg, { getState, rejectWithValue }) => {
        try {
            // get user data from store
            // const { user } = getState()
            // console.log(`Bearer ${localStorage.getItem("userToken")}`);
            // configure authorization header with user's token
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("userToken")}`,
                },
            }
            // console.log(localStorage.getItem("userToken"))
            const { data } = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/test-orders`,
                config
            )

            return data
        } catch (error) {
            console.log(error);
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)


// DELETE LAB ORDERS
export const deleteTestOrder = createAsyncThunk(
    'laboratory/test/order/delete',
    async (arg, { getState, rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("userToken")}`,
                },
            }
            // console.log(localStorage.getItem("userToken"))
            const { data } = await axios.delete(
                `${process.env.REACT_APP_BASE_URL}/test-orders/${arg}`,
                config
            )
                .then((res) => toast.success('Test order delete success', {
                    position: toast.POSITION.BOTTOM_RIGHT
                }))
            console.log(data);
            return data
        } catch (error) {
            console.log(error);
            toast.error(error?.response?.data?.error_description, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)
