import { createSlice } from '@reduxjs/toolkit'
import { acceptDrugOrder, addDrug, deleteDrug, deleteDrugOrder, deleteDrugOrderByPharmacist, getAllDrugOrderList, getDrugCategoryList, getDrugList, getPharmacyByUserId } from './pharmaciestAction'


const initialState = {
    //get drug category
    loading: false,
    drugCategory: [],
    error: null,
    success: false,

    //get drug list
    drugListLoading: false,
    drugs: [],
    drugListError: null,
    drugListSuccess: false,


    //get one pharmacy detial
    getOnePharmacyLoading: false,
    pharmacy: {},
    getOnePharmacyError: null,
    getOnePharmacySuccess: false,


    //add drug
    addDrugLoading: false,
    addDrugError: null,
    addDrugSuccess: false,

    //delete drug
    deleteDrugLoading: false,
    deleteDrugError: null,
    deleteDrugSuccess: false,

    //get drug order list
    getDrugOrderListLoading: false,
    getDrugOrderListError: null,
    getDrugOrderList: [],
    getDrugOrderListSuccess: false,



    //accept drug orders
    acceptDrugOrderLoading: false,
    acceptDrugOrderError: null,
    acceptDrugOrderSuccess: false,

    //deleteDrugOrderByPharmacist
    deleteDrugOrderByPharmacistLoading: false,
    deleteDrugOrderByPharmacistError: null,
    deleteDrugOrderByPharmacistSuccess: false,
}
const pharmaciestSlice = createSlice({
    name: 'pharmaciest',
    initialState,
    reducers: {
    },
    extraReducers: {
        // get pharmacy list
        [getDrugCategoryList.pending]: (state) => {
            state.loading = true
        },
        [getDrugCategoryList.fulfilled]: (state, { payload }) => {
            console.log("in action:  " + payload);
            state.loading = false
            state.drugCategory = payload
        },
        [getDrugCategoryList.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },
        // deleteDrugOrderByPharmacistLoading
        [deleteDrugOrderByPharmacist.pending]: (state) => {
            state.deleteDrugOrderByPharmacistLoading = true
        },
        [deleteDrugOrderByPharmacist.fulfilled]: (state, { payload }) => {
            state.deleteDrugOrderByPharmacistLoading = false
            state.deleteDrugOrderByPharmacistSuccess = true
        },
        [deleteDrugOrderByPharmacist.rejected]: (state, { payload }) => {
            state.deleteDrugOrderByPharmacistLoading = false
            state.deleteDrugOrderByPharmacistError = payload
        },


        // acceptDrugOrder
        [acceptDrugOrder.pending]: (state) => {
            state.acceptDrugOrderLoading = true
        },
        [acceptDrugOrder.fulfilled]: (state, { payload }) => {
            console.log("in action:  " + payload);
            state.acceptDrugOrderLoading = false
            state.acceptDrugOrderSuccess = true
        },
        [acceptDrugOrder.rejected]: (state, { payload }) => {
            state.acceptDrugOrderLoading = false
            state.acceptDrugOrderError = payload
        },


        // get drugs list
        [getDrugList.pending]: (state) => {
            state.drugListLoading = true
        },
        [getDrugList.fulfilled]: (state, { payload }) => {
            state.drugListLoading = false
            state.drugs = payload
        },
        [getDrugList.rejected]: (state, { payload }) => {
            state.drugListLoading = false
            state.drugListError = payload
        },


        // get one pharmacy
        [getPharmacyByUserId.pending]: (state) => {
            state.getOnePharmacyLoading = true
        },
        [getPharmacyByUserId.fulfilled]: (state, { payload }) => {
            state.getOnePharmacyLoading = false
            state.pharmacy = payload
        },
        [getPharmacyByUserId.rejected]: (state, { payload }) => {
            state.getOnePharmacyLoading = false
            state.getOnePharmacyError = payload
        },

        // Add Drug
        [addDrug.pending]: (state) => {
            state.addDrugLoading = true
        },
        [addDrug.fulfilled]: (state, { payload }) => {
            state.addDrugLoading = false
            state.addDrugSuccess = payload
        },
        [addDrug.rejected]: (state, { payload }) => {
            state.addDrugLoading = false
            state.addDrugError = payload
        },

        // Delete Drug
        [deleteDrug.pending]: (state) => {
            state.deleteDrugLoading = true
        },
        [deleteDrug.fulfilled]: (state, { payload }) => {
            state.deleteDrugLoading = false
            state.deleteDrugSuccess = payload
        },
        [deleteDrug.rejected]: (state, { payload }) => {
            state.deleteDrugLoading = false
            state.deleteDrugError = payload
        },
        // get All Drug Order List 
        [getAllDrugOrderList.pending]: (state) => {
            state.getDrugOrderListLoading = true
        },
        [getAllDrugOrderList.fulfilled]: (state, { payload }) => {
            console.log(payload);
            state.getDrugOrderListLoading = false
            state.getDrugOrderList = payload
        },
        [getAllDrugOrderList.rejected]: (state, { payload }) => {
            state.getDrugOrderListLoading = false
            state.getDrugOrderListError = payload
        },


    },
})

export default pharmaciestSlice.reducer