import { createSlice } from '@reduxjs/toolkit'
import { deleteLaboratories, getLabDetailById, getLaboratoriesList, getPendingLabList } from './labAction';


const initialState = {
    loading: false,
    labs: [],
    error: null,
    success: false,

   //get lab by id
    LabByIdloading: false,
    LabByIdLabs: {},
    LabByIdError: null,
    LabByIdSuccess: false,

    //for delete laboratories
    deleteLoading: false,
    DeletError: null,
    deleteSuccess: false,


    //pending labbs
    PendingLabs: {}
}
const labSlice = createSlice({
    name: 'labs',
    initialState,
    reducers: {
    },
    extraReducers: {

        // get laboratory list
        [getLaboratoriesList.pending]: (state) => {
            console.log('labs list fetch loading...');
            state.loading = true
        },
        [getLaboratoriesList.fulfilled]: (state, { payload }) => {
            console.log('labs list fetched...');
            state.loading = false
            state.labs = payload
        },
        [getLaboratoriesList.rejected]: (state, { payload }) => {
            console.log('labs list rejected...');
            state.loading = false
            state.error = payload
        },


        // get laboratory by id
        [getLabDetailById.pending]: (state) => {
            state.LabByIdloading = true
        },
        [getLabDetailById.fulfilled]: (state, { payload }) => {
            state.LabByIdloading = false
            state.LabByIdLabs = payload
            state.LabByIdSuccess = true
        },
        [getLabDetailById.rejected]: (state, { payload }) => {
            state.LabByIdloading = false
            state.LabByIdError = payload
        },

        ////------------------------------------------------
        // delete labotratories
        ////------------------------------------------------------------------------
        [deleteLaboratories.pending]: (state) => {
            state.deleteLoading = true
        },
        [deleteLaboratories.fulfilled]: (state, { payload }) => {
            state.deleteLoading = false
        },
        [deleteLaboratories.rejected]: (state, { payload }) => {
            state.deleteLoading = false
            state.deleteError = payload
        },

        ////-----------------------------------------------------------------------------------------
        // get Pending labs list
        ////-----------------------------------------------------------------------------------------

        [getPendingLabList.pending]: (state) => {
            state.loading = true
        },
        [getPendingLabList.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.PendingLabs = payload
        },
        [getPendingLabList.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload

        },
    },
})

export default labSlice.reducer