import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { toast } from 'react-toastify'
// labotarotyAction for all laboratory cases
export const getLaboratoriesList = createAsyncThunk(
    'laboratory/list',
    async (arg, { getState, rejectWithValue }) => {
        try {

            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("userToken")}`,
                },
            }
            // console.log(localStorage.getItem("userToken"))
            const { data } = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/laboratories`,
                config
            )
            // .then((res) => res)
            // .catch(function (error) {
            //     if (error.response) {
            //         // The request was made and the server responded with a status code
            //         // that falls out of the range of 2xx
            //         console.log(error.response);
            //         console.log(error.response.status);
            //         console.log(error.response.headers);
            //     } else if (error.request) {
            //         // The request was made but no response was received
            //         // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            //         // http.ClientRequest in node.js
            //         console.log(error.request);
            //     } else {
            //         // Something happened in setting up the request that triggered an Error
            //         console.log('Error', error.message);
            //     }
            //     console.log(error.config);
            // });
            // console.log(data);

            return data
        } catch (error) {
            console.log(error);
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)

// DELETE LABs
export const deleteLaboratories = createAsyncThunk(
    'laboratory/one/delete/',
    async (arg, { getState, rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("userToken")}`,
                },
            }
            const { data } = await axios.delete(
                `${process.env.REACT_APP_BASE_URL}/laboratories/${arg}`,
                config
            )
                .then((res) => toast.success('Laboratory delete success', {
                    position: toast.POSITION.BOTTOM_RIGHT
                }))

            console.log(data);
            return data
        } catch (error) {
            console.log(error);
            toast.error(error?.response?.data?.error_description, {
                position: toast.POSITION.BOTTOM_RIGHT
            })
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)
export const getPendingLabList = createAsyncThunk(
    'PendingLabs/list',
    async (arg, { getState, rejectWithValue }) => {
        try {
            // get user data from store
            const { user } = getState()
            console.log(` ${user}`);
            // configure authorization header with user's token
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("userToken")}`,
                },
            }
            // console.log(localStorage.getItem("userToken"))
            const { data } = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/laboratories/pending`,
                config
            )
            return data
        } catch (error) {
            console.log(error);
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)

// get one pharmacy detail.js
export const getLabDetailById = createAsyncThunk(
    'getLabDetailById/',
    async (arg, { getState, rejectWithValue }) => {
        try {
            // get user data from store
            // const { user } = getState()
            // console.log(`Bearer ${localStorage.getItem("userToken")}`);
            // configure authorization header with user's token
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("userToken")}`,
                },
            }
            // console.log(localStorage.getItem("userToken"))
            const { data } = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/laboratories/${arg}`,
                config
            )
            return data
        } catch (error) {
            console.log(error);
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)
