import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-toastify';
// doctorAction.js
export const getDoctorsList = createAsyncThunk(
    'doctors/list',
    async (arg, { getState, rejectWithValue }) => {
        try {
            // get user data from store
            const { user } = getState()
            console.log(` ${user}`);
            // configure authorization header with user's token
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("userToken")}`,
                },
            }
            // console.log(localStorage.getItem("userToken"))
            const { data } = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/doctors`,
                config
            )


            return data
        } catch (error) {
            console.log(error);
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)
export const deleteDoctor = createAsyncThunk(
    'doctors/delete',
    async (arg, { getState, rejectWithValue }) => {
        try {
            // get user data from store
            const { user } = getState()
            console.log(` ${user}`);
            // configure authorization header with user's token
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("userToken")}`,
                },
            }
            // console.log(localStorage.getItem("userToken"))
            const { data } = await axios.delete(
                `${process.env.REACT_APP_BASE_URL}/doctors${arg}`,
                config
            )
                .then((res) => {
                    if (res) {
                        toast.success('Doctor Deletion success', {
                            position: toast.POSITION.BOTTOM_RIGHT
                        });

                    }
                })
            return data
        } catch (error) {
            console.log(error);
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)
export const getPendingDoctorsList = createAsyncThunk(
    'Pendingdoctors/list',
    async (arg, { getState, rejectWithValue }) => {
        try {
            // get user data from store
            const { user } = getState()
            console.log(` ${user}`);
            // configure authorization header with user's token
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("userToken")}`,
                },
            }
            // console.log(localStorage.getItem("userToken"))
            const { data } = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/doctors/pending`,
                config
            )
            return data
        } catch (error) {
            console.log(error);
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)

export const acceptRejectDoctor = createAsyncThunk(
    'accept/reJect/doctor',
    async (arg, { getState, rejectWithValue }) => {
        console.log(arg);
        try {
            // configure authorization header with user's token
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("userToken")}`,
                },
            }
            // console.log(localStorage.getItem("userToken"))
            const { data } = await axios.put(
                `${process.env.REACT_APP_BASE_URL}/admins/approve/?id=${arg}&status=ACTIVE&role=DOCTOR`,
                config
            )
            return data
        } catch (error) {
            console.log(error);
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)

//add doctor service which is directly connect to the backend
export const addDoctor = createAsyncThunk(
    'doctor/create/new',
    async (arg, { getState, rejectWithValue }) => {
        console.log(arg);
        try {
            // configure authorization header with user's token
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("userToken")}`,
                    'Content-Type': 'application/json'
                },
            }

            const data = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/doctors`, arg,
                config
            )
                .then((res) => toast.success('Doctor Registration success', {
                    position: toast.POSITION.BOTTOM_RIGHT
                }))
            console.log(data);
            return data
        } catch (error) {
            console.log(error);
            if (error?.response?.data?.error_message !== "") {
                toast.error(error?.response?.data?.error_message, {
                    position: toast.POSITION.BOTTOM_RIGHT
                })
            }
            else if (error?.response?.data?.field_errors.length !== 0) {
                toast.error(error?.response?.data?.field_errors[0], {
                    position: toast.POSITION.BOTTOM_RIGHT
                })
            }
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)
